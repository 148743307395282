exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-crossword-tsx": () => import("./../../../src/pages/crossword.tsx" /* webpackChunkName: "component---src-pages-crossword-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-multigames-tsx": () => import("./../../../src/pages/multigames.tsx" /* webpackChunkName: "component---src-pages-multigames-tsx" */),
  "component---src-pages-privacy-old-tsx": () => import("./../../../src/pages/privacy-old.tsx" /* webpackChunkName: "component---src-pages-privacy-old-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

